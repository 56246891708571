#about-page h1, h2, h3{
	font-family: manual-condensed-bold-italic;
}

#about-page {
    overflow-x: hidden;
    padding: 0px
}

.about-video-container {
    width: 100%;
	height: 100%;
}

.about-video {
    position: absolute;
    width: 100%;
	pointer-events: none;
	object-fit: cover;
	filter: brightness(80%);
}

#overview-video {
	height: 170%;
}

#team-video {
    top: 170%;
    height: 310%
}

#facilities-video {
    top: 480%;
    height: 360%
}

.about-video-overlay-header{
    position: absolute;
    text-align: center;
    color: white;
	z-index: 1;
	width: 55%;
	height: 20%;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 1.5em;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#overview-header {
    top: 25%;
}

#team-header {
    top: 190%;
    height: 15%;
}

#facilities-header {
    top: 500%;
    height: 15%;
}

.about-section {
    position: absolute;
    background: white;
	z-index: 1;
    padding: 3em;
    border: 2px solid rgb(39, 61, 180);
    border-radius: 1em;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

#overview-section {
    top: 50%;
    width: 90%;
}

#team-section {
    top: 220%; 
}

#facilities-section {
    top: 535%;
}

#standards-section {
    top: 640%;
}

.about-section-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-section-content{
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: center;
}


.about-section-img {
    margin-top: 1vh; 
    border: 2px solid rgb(39, 61, 180);
    border-radius: 5%;
}

#facilities-img {
    height: 70vh;
}

.about-team-section {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5vh 10vw;
    margin-top: 15vh !important;
}

.secondary-section {
    margin-top: 0vh !important;
}

.about-team-section h2 {
    color: rgb(39, 61, 180);
}
.about-team-img {
    border-radius: 50%;
    border: 2px solid rgb(39, 61, 180);
    width: 233px;
    height: 233px;
}

#about-page-footer {
    position: absolute;
    top: 841%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Standards */

#standards{
    padding-top: 10vh;
    padding-bottom: 5vh;
    background-color: white;
}

#about-standards-header {
    text-align: center;
}

.about-standards-row {
    width: 75% !important;
    margin-left: 12.5% !important;
    margin-bottom: 5vh;
}

#about-compliance-cards{
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}


.about-compliance-card{ 
    width: 15em;
    max-height: 4em;
    margin: 1em;
    color: white !important;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .about-compliance-card{ 
        margin-bottom: 5vh;
    }
}
/* <------------------------------------> */

/* xx-small */
@media screen and (max-width: 439px) {
    
    #about-page-footer {
        position: absolute;
        top: 1395%;
    }
    
    #overview-video {
        height: 260%;
    }

    #team-video {
        top: 260%;
        height: 515%
    }

    #facilities-video {
        top: 775%;
        height: 620%;
    }

    .about-video-overlay-header{
        width: 85%;
    }

    #team-header {
        top: 275%;
    }

    #facilities-header{
        top: 790%;
    }

    #team-section {
        top: 295%; 
    }

    #facilities-section {
        top: 825%;
    }
    
    #standards-section {
        top: 995%;
    }

    .about-team-section h2 {
        font-size: 1.25em;
        margin-bottom: 1em;
    }
}

/* x-small */
@media screen and (min-width: 440px) and (max-width: 575px) {
    
    #about-page-footer {
        position: absolute;
        top: 1256%;
    }
    
    #overview-video {
        height: 245%;
    }

    #team-video {
        top: 245%;
        height: 485%
    }

    #facilities-video {
        top: 730%;
        height: 525%
    }

    .about-video-overlay-header{
        width: 85%;
    }

    #team-header {
        top: 260%;
    }

    #facilities-header {
        top: 750%;
    }

    #team-section {
        top: 285%; 
    }

    #facilities-section {
        top: 775%;
    }

    #standards-section {
        top: 930%;
    }

    .about-team-section h2 {
        font-size: 1.5em;
        margin-bottom: 1em;
    }

}


/* small */
@media screen and (min-width: 576px) and (max-width: 767px) {
    
    #about-page-footer {
        position: absolute;
        top: 1121%;
    }
    
    #overview-video {
        height: 220%;
    }

    #team-video {
        top: 220%;
        height: 425%
    }

    #facilities-video {
        top: 645%;
        height: 475%;
    }

    .about-video-overlay-header{
        width: 75%;
    }
    
    #team-header {
        top: 235%;
        height: 10%;
    }

    #facilities-header {
        top: 660%;
    }

    #team-section {
        top: 255%; 
    }

    #facilities-section {
        top: 685%;
    }

    #standards-section {
        top: 825%;
    }

    .about-team-section h1 {
        font-size: 2em;
    }
    .about-team-section h2 {
        font-size: 1.5em;
    }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {

    #about-page-footer {
        position: absolute;
        top: 1001%;
    }

    #overview-video {
        height: 225%;
    }

    #team-video {
        top: 225%;
        height: 350%
    }

    #facilities-video {
        top: 575%;
        height: 425%;
    }

    .about-video-overlay-header{
        width: 75%;
    }
    
    #team-header {
        top: 245%;
    }

    #facilities-header {
        top: 600%;
    }

    #team-section {
        top: 275%; 
    }

    #facilities-section {
        top: 625%;
    }
    
    #standards-section {
        top: 760%;
    }

    .about-team-section h1 {
        font-size: 2em;
    }
    .about-team-section h2 {
        font-size: 1.5em;
    }
}

/* large */
@media screen and (min-width: 992px) and (max-width: 1199px) {

    #about-page-footer {
        position: absolute;
        top: 885%;
    }

	#overview-video {
        height: 200%;
    }

    #team-video {
        top: 200%;
        height: 330%
    }

    #facilities-video {
        top: 530%;
        height: 355%;
    }

    .about-video-overlay-header{
        width: 65%;
    }
     
    #team-header {
        top: 215%;
    }

    #facilities-header {
        top: 545%;
    }

    #team-section {
        top: 245%; 
    }

    #standards-section {
        top: 675%;
    }

    #facilities-section {
        top: 570%;
    }

}