#tc-navbar {
    background-color: white;
    border-bottom: 5px solid rgb(39, 61, 180);
    font-family: manual-condensed-bold-italic;
}

@media screen and (min-width: 992px) {
    #tc-navbar-brand{
        margin-left: 15%;
    }
    
    #tc-nav{
        width: 50%;
        margin-left: 40%;
    }
}
@media screen and (max-width: 991px){

    #tc-navbar-offcanvas-body{
        margin-top: 10%;
        font-size: large;
        font-family: manual-condensed-bold-italic;
    }
    
    .tc-nav-item{
        margin-top: 5%;
    }

    .dropdown-menu{
        text-align: center !important;
        font-size: 20px !important;
        border-color: rgb(39, 61, 180) !important;
    }
}
