body {
  background-color: rgb(39, 61, 180) !important;
  overflow-x: hidden;
}

.btn-primary{
  --bs-btn-color: #fff;
  --bs-btn-bg: rgb(39, 61, 180) !important;
  --bs-btn-border-color: rgb(39, 61, 180) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgb(39, 61, 180) !important;
  --bs-btn-disabled-border-color: rgb(39, 61, 180) !important;
}

@font-face {
	font-family: manual-condensed-bold-italic;
	src: url("../fonts/manual-condensed-bold-italic.otf");
}

.footer {
  width: 100%;
  height: 2.5rem;
  background-color: rgb(39, 61, 180);
  color: white;
  text-align: center;
  padding-top: 0.5rem;
  font-size: 0.85em;
}