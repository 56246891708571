#services-page h1, h2, h3, h4{
	font-family: manual-condensed-bold-italic;
}

.carousel-arrow-left, .carousel-arrow-right {
    color: rgb(39, 61, 180);
    width: 5vw;
    height: 5vh;
    cursor: pointer;
    margin: 0.5em;
}

#services-page {
    margin-top: 5vh;
    overflow-x: hidden;
    padding: 0px;
}


/* Overview */


#services-page-banner-title {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 35vh;
    z-index: 1;
}

#services-page-banner-image {
    height: 35vh;
    width: 100%;
    object-fit: cover;
    filter: brightness(65%);
}

#services-overview-body {
    background: white;
    text-align: center;
    padding-top: 1em;
}


#service-overview-buttons {
    padding-top: 2em;
    padding-bottom: 5em;
}

.service-overview-button-col{
    padding: 1em 0 !important;
}

.service-overview-button {
    font-family: manual-condensed-bold-italic !important;
    font-size: 1.4em !important;
    width: 60%;
    max-height: 100%;
}

.service-overview-button div{
    padding: 0.5rem;
}

/* Services Info */

.services-video {
	width: 100%;
	height: 50vh;
	pointer-events: none;
	object-fit: cover;
	filter: brightness(80%);
    margin-left: 0;
}

.services-video-overlay-header{
    text-align: center;
    color: white;
	z-index: 1;
    position: relative;
	width: 75%;
	height: 40%;
    margin-left: 10%;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 1.5em;
    margin-top: -15%
}

.services-info {
    background-color: white;
    padding-top: 5em;
    padding-bottom: 1em;
    margin-top: 15vh !important;
}

.service-card {
    margin: 1em;
    padding: 1em;
    border: 2px solid rgb(39, 61, 180) !important;
    border-radius: 5px;
    min-height: 32.5rem;
}

.service-card img {
    width: 70%;
    margin: auto;
    display: block;
}

.service-card-title {
    display: flex;
    align-items: center;
    justify-content: left;
}

.service-card-row {
    margin-top: 1%;
    justify-content: space-around;
}

.service-material-card-row{
    margin: 2em auto !important;
}

.service-material-card-col{
    margin: 1em auto;
}

.service-material-card {
    width: 15em;
    max-height: 4em;
    color: white !important;
    text-align: center;
}

.service-material-card-hidden{
    display: none !important;
}

/* x-small */
@media screen and (max-width: 575px) {
	.services-video-overlay-header{
        margin-left: auto;
        margin-right: auto;
        margin-top: -15.5em
    }

}

/* medium */
@media screen and (min-width: 768px) and (max-width: 992px) {
    .services-video-overlay-header{
        margin-left: auto;
        margin-right: auto;
        margin-top: -20.5em
    }
}