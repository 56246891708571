#home-page h1{
	font-family: manual-condensed-bold-italic;
}

#home-page {
	overflow-x: hidden;
}

.home-video {
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	object-fit: cover;
	filter: brightness(80%);
    margin-left: 0;
}

.home-video-overlay-header{
    text-align: center;
    color: white;
	z-index: 1;
    position: absolute;
	width: 35em;
	height: 10em;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 1.5em;
    top: 60%;
	left: 5%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.home-video-overlay-header > h1{
	font-size: 1.65em;
}

#home-info {
	color: white;
	width: 100%;
	background-color: rgb(39, 61, 180);
}

#home-info h1 {
	font-size: x-large;
}

#home-info-row {
	margin-top: 3% !important;
	margin-left: 1% !important;
}

#home-info-col{
	margin-bottom: 3em;
}

.contact-info-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1em;
}

.contact-info-container a{
	text-decoration: none;
	color: white
}

.contact-info-information{
	display: flex;
	flex-direction: column;
}

.contact-info-container a:hover{
	text-decoration: underline;
	color: white
}

.contact-info-icon {
	font-size: 2em;
	margin-right: 0.5em;
}

#home-contact-map {
	min-width: 30vw;
	min-height: 45vh;

}

#home-about-us-info-container{ 
	width: 90%;
}

#home-social-media-info{
	margin-top: 5%;
}

.home-social-media-icons a{
	text-decoration: none;
}

.home-social-media-icon{
	color: white;
	font-size: 2em;
	margin-right: 0.5em;
}

#home-download-info{
	margin-top: 5%;
}

#home-download-info-container a{
	text-decoration: none;
	color: white;
	display: flex;
	align-items: center;
	flex-direction: row;
}

#home-download-info-container a:hover{
	text-decoration: underline;
	color: white;
}

#home-download-info-icon{
	font-size: 1.25em;
	margin-right: 0.5em;
}

/* x-small */
@media screen and (max-width: 575px) {
	.home-video-overlay-header{
		width: 20em;
		height: 8em;
		top: 75%;
		left: 10%;
	}
	
	.home-video-overlay-header > h1{
		font-size: 1.25em;
	}
}

/* small to medium */
@media screen and (min-width: 576px) and (max-width: 991px) {
	.home-video-overlay-header{
		top: 75%;
	}
}

/* large */
@media screen and (min-width: 992px) and (max-width: 1199px) {
	.home-video-overlay-header{
		top: 75%;
	}
	.home-video-overlay-header > h1{
		font-size: 2em;
	}
}